import React from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { notificationConstants } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import useSound from 'use-sound';
import notificationSound from '../../../sounds/notification-ring.mp3'; // Replace this with the actual sound

function ActionCable(props) {
  const dispatch = useDispatch();
  const [play, { stop }] = useSound(notificationSound);
  const user_id = useSelector(state => state.dashboard.currentUser);
  const enable_notification_ring = useSelector(state => state.notification.enable_notification_ring);
  
  const handleReceivedLists=(res)=> {
    if(res?.deleted){
      dispatch({ type: notificationConstants.DELETE_ACTION_CABLE_NOTIFICATIONS, payload: res.data })
    } else {
      dispatch({ type: notificationConstants.GET_ACTION_CABLE_NOTIFICAIONS, payload: res.data })
      const notification = res?.data;
      
      if(
          notification?.is_read === false && 
          notification?.summary !== "-" && 
          notification?.viewable && 
          enable_notification_ring && 
          notification?.is_sound_enabled
        ){
        play();
      }
    }
  }


  return (
    <ActionCableConsumer
      key={user_id && user_id.id }
      channel={{ channel: "NotificationChannel", user_id: user_id && user_id.id }}
      onConnected={() => "Connected to server."}
      onReceived={handleReceivedLists} 
    />
  );
}

export default ActionCable



