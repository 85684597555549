import { alertActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makeDELETERequest, makeFormDataPUTRequest, makePOSTRequest, makeFormDataPOSTRequest, controlledGETRequest } from '../utils/Axios';
import { adminBaseURL, buildFormDataFromObject } from '../utils/commonUtils';
import { reset, change } from "redux-form";

export const commentActions = {
  createComment,
  getComment,
  getCommentControlled,
  updateComment,
  newComment,
  showComment,
  deleteComment,
};

export function createComment(comment, workspace_id, content_group_id, activeTab, page, per_page, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments`, comment)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.COMMENT_REQUEST, payload: response})
          dispatch(change("commentForm", "content_id", ""));
          dispatch(change("commentForm", "description", ''));

          dispatch({ type: userConstants.CREATED_COMMENT, payload: response})

          //const load = true
          //dispatch(getComment(workspace_id, content_group_id, activeTab, load, page, per_page));
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: userConstants.COMMENT_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_FAILURE,
        payload: console.log(e),
      })
    }

    if (setIsDisabled) {
      setIsDisabled(false)
    }
  }
}

export function updateComment(comment, workspace_id, content_group_id, comment_id, callback) {
  return function (dispatch) {
    try{
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments/${comment_id}`, comment)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.COMMENT_REQUEST, payload: response})
          dispatch(change("commentForm", "content_id", ""));
          dispatch(change("commentForm", "description", ''));

          dispatch({ type: userConstants.UPDATED_COMMENT, payload: response})
          dispatch(alertActions.success(userConstants.UPDATED_COMMENT));
          callback(response.data);
        }else {
          dispatch(alertActions.error(response.data.message));
          callback();
          dispatch({ type: userConstants.COMMENT_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function showComment(workspace_id, content_group_id, comment_id, show_activities, chat_status, page, per_page, callback) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.COMMENT_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments/${comment_id}`, {show_activities, chat_status, page, per_page})
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_COMMENT,
            payload: response
          })

          if(callback){
            callback(response.data)
          }
        }else {
          history.push('/');
          dispatch({ type: userConstants.COMMENT_FAILURE, payload: response.data.message});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function getComment(workspace_id, content_group_id, chat_status, load, show_activities, page, per_page, callback) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  return function (dispatch) {
    try{
      if(load){
        dispatch({ type: userConstants.COMMENT_LOADING });
      }
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments`, {show_activities, chat_status, page, per_page})
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_COMMENT,
            payload: response
          })

          if(callback){
            callback(response.data.data)
          }
        }else {
          history.push('/');
          dispatch({ type: userConstants.COMMENT_FAILURE, payload: response.data.message});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function getCommentControlled(workspace_id, content_group_id, chat_status, load, show_activities, page, per_page, callback, cancel_token) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  return function (dispatch) {
    try{
      if(load){
        dispatch({ type: userConstants.COMMENT_LOADING });
      }
      controlledGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments`, cancel_token, {show_activities, chat_status, page, per_page})
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_COMMENT,
            payload: response
          })

          if(callback){
            callback(response.data.data)
          }
        }else {
          history.push('/');
          dispatch({ type: userConstants.COMMENT_FAILURE, payload: response.data.message});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function newComment(response) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.NEW_COMMENT, payload: response})
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function deleteComment(workspace_id, content_group_id, comment_id, successCallback) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments/${comment_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.DELETE_COMMENT, payload: response});
          dispatch(alertActions.success(userConstants.DELETE_COMMENT));
          successCallback(response)
        }else {
          dispatch(alertActions.error(response.data.message));
          successCallback(response)
          dispatch({ type: userConstants.COMMENT_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
