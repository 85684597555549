import { alertActions, userActions } from './';
import { userConstants, notificationConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makeFormDataPUTRequest, makeDELETERequest, makeFormDataPOSTRequest, makePOSTRequest, makePUTRequest } from '../utils/Axios';

export const user_profileActions = {
  editUserProfile,
  destroyUserProfile,
  updateUserProfile,
  updateUserPassword,
  updateNotificationRingSetting,
  showUser
};

export function updateUserProfile(reqparams, handleCloseUserProfileForm) {
  return function (dispatch) {
    try{
      // dispatch({ type: userConstants.PAGE_LOADING });
      makeFormDataPUTRequest(`/api/v1/users/update_detail`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_USERPROFILE,
            payload: response
          });
          dispatch({type: userConstants.CURRENT_USER_DATA, payload: response.data.data})
          dispatch(alertActions.success(userConstants.UPDATE_USERPROFILE));
          handleCloseUserProfileForm()
        }else{
          dispatch({ type: userConstants.USERPROFILE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.USERPROFILE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateNotificationRingSetting(value, success_callback){
  return function (dispatch) {
    try{
      makeFormDataPUTRequest(`/api/v1/users/update_notification_ring`, value)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(alertActions.success(notificationConstants.UPDATE_NOTIFICATION_RING));

          if(success_callback){
            success_callback(response.data)
          }
        }else{
          dispatch({ type: notificationConstants.UPDATE_NOTIFICATION_RING_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: notificationConstants.UPDATE_NOTIFICATION_RING_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateUserPassword(reqparams, handleCloseUserProfileForm) {
  return function (dispatch) {
    try{
      makeFormDataPUTRequest(`/api/v1/users/update_password`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_USERPASSWORD,
            payload: response
          });
          dispatch(alertActions.success(userConstants.UPDATE_USERPASSWORD));
          handleCloseUserProfileForm()
        }else{
          dispatch({ type: userConstants.USERPROFILE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.USERPROFILE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editUserProfile(id) {
  return function (dispatch) {
    try{
      // dispatch({ type: userConstants.PAGE_LOADING });
      makePOSTRequest(`/api/v1/users/detail`)
      .then(response => {
        dispatch({
          type: userConstants.EDIT_USERPROFILE,
          payload: response
        })
      })
    }catch(e){
      dispatch( {
        type: userConstants.USERPROFILE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyUserProfile(callback) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/users/destroy`)
      .then(response => {

        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.DELETE_USERPROFILE,
            payload: response
          });
          callback();
          const show_flash = false;
          const custom_flash = () => {
            dispatch(alertActions.success(userConstants.DELETE_USERPROFILE));
          }
          dispatch(userActions.logout(custom_flash));
        }else{
          dispatch({ type: userConstants.USERPROFILE_FAILURE })
          callback();
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.USERPROFILE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function showUser(user_id, workspace_id) {
  return dispatch => {
    try{
      dispatch({ type: userConstants.USER_PROFILE_LOADING });

      makeGETRequest(`/api/v1/multi_users/${user_id}/get_summary?workspace_id=${workspace_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.USER_PROFILE,
            payload: response
          })
        } else {
          history.push('/');
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.USERPROFILE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
