import { alertActions, work_spaceActions, contentActions, getComment, commentActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import {
        makeGETRequest,
        makePOSTRequest,
        makeDELETERequest,
        makeFormDataPOSTRequest,
        makeFormDataPUTRequest,
        makeFormDataGETRequest,
        makePUTRequest
      } from '../utils/Axios';
import { reset } from "redux-form";
import { adminBaseURL, buildFormDataFromObject } from '../utils/commonUtils';

export const content_groupActions = {
  newContentGroup,
  createContentGroup,
  getContentGroup,
  editContentGroup,
  destroyContentGroup,
  updateContentGroup,
  submitContentGroup,
  voidSubmitContentGroup,
  approveContentGroup,
  assignContentLabelToContentGroup,
  rejectContentGroup,
  requestRevisionContentGroup,
  archiveContentGroup,
  updateStageContentGroup,
  acceptRequestContentGroup,
  rejectRequestContentGroup,
  inlineEditContentGroup,
  inlineNewContentGroup,
  replaceContentImageLazeLoading,
  searchContentGroup,
  deleteSearchContentGroup,
  setSearchHistoryContentGroup,
  recommendContentGroup,
  resetRecommendationContentGroup
};


export function newContentGroup(workspace_id, openModal, stage_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/new`)
      .then(response => {
        if(response.data.error){
          history.push(`/`);
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch({ type: userConstants.NEW_CONTENTGROUP, payload: response});
          openModal({show: true, stage_id: stage_id});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function createContentGroup(reqparams, workspace_id, handleSuccess) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makePOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          handleSuccess(response.data.id);
          dispatch({ type: userConstants.CONTENTGROUP_REQUEST, payload: response})
          dispatch(reset('content_groupForm'));
          dispatch(content_groupActions.getContentGroup(workspace_id));
          dispatch(alertActions.success(userConstants.CONTENTGROUP_REQUEST));
          dispatch({ type: userConstants.CREATED_CONTENTGROUP, payload: response})
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateContentGroup(reqparams, content_group_id, workspace_id, callback) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makePUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(content_groupActions.getContentGroup(workspace_id));
  
          const getContentParams = {
            workspace_id,
            content_group_id,
          };

          dispatch(contentActions.getContent(getContentParams));
          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          if(callback) callback();
          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, [content_group_id]));
          dispatch(alertActions.success(userConstants.UPDATE_CONTENTGROUP));
        }else{
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error});
          if(callback) callback();
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })

      if(callback) callback();
    }
  }
}

export function updateStageContentGroup(workspace_id, content_group_id, stage_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makePUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/update_stage?stage_id=${stage_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          dispatch(alertActions.success(userConstants.UPDATE_CONTENTGROUP));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function submitContentGroup(content_group_id, workspace_id, stage_id, activeTab, objValues, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  let formData = new FormData();
  buildFormDataFromObject(formData, objValues, "comment");

  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/approved_content_group_stages?stage_id=${stage_id}`, formData)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.SUBMIT_CONTENTGROUP,
            payload: response
          })

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          //dispatch(commentActions.createComment(formData, workspace_id, content_group_id, activeTab));
          dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, objValues.parent_id == null, true));
          dispatch(alertActions.success(userConstants.SUBMIT_CONTENTGROUP));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
    setIsDisabled(false);
  }
}

export function searchContentGroup(workspace_id, data) {
  return function (dispatch) {
    try{
      dispatch({
        type: userConstants.SEARCH_CONTENTGROUP,
        payload: { workspace_id: workspace_id, text: data }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function resetRecommendationContentGroup() {
  return function (dispatch) {
    try{
      dispatch({
        type: userConstants.RESET_RECOMMENDATION_CONTENTGROUP
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function deleteSearchContentGroup(workspace_id, data) {
  return function (dispatch) {
    try{
      dispatch({
        type: userConstants.DELETE_SEARCH_CONTENTGROUP,
        payload: {workspace_id: workspace_id, text: data}
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function setSearchHistoryContentGroup(data) {
  return function (dispatch) {
    try{
      dispatch({
        type: userConstants.SET_SEARCH_HISTORY_CONTENTGROUP,
        payload: data
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function recommendContentGroup(workspace_id, name) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.RECOMMEND_CONTENTGROUP_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/search?name=${name}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.RECOMMEND_CONTENTGROUP,
            payload: response
          })
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function voidSubmitContentGroup(content_group_id, workspace_id, approved_content_group_stage_id, activeTab, objValues, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  let formData = new FormData();
  buildFormDataFromObject(formData, objValues, "comment");

  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/approved_content_group_stages/${approved_content_group_stage_id}/void_submit`, formData)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.VOID_SUBMIT_CONTENTGROUP,
            payload: response
          })

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          // dispatch(commentActions.createComment(formData, workspace_id, content_group_id, activeTab));
          dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, objValues.parent_id == null, true));
          dispatch(alertActions.success(userConstants.VOID_SUBMIT_CONTENTGROUP));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }

    setIsDisabled(false);
  }
}

export function acceptRequestContentGroup(content_group_id, workspace_id, approved_content_group_stage_id, activeTab, objValues, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  let formData = new FormData();

  buildFormDataFromObject(formData, objValues, "comment");

  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/approved_content_group_stages/${approved_content_group_stage_id}/accept_revision`, formData)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.ACCEPT_REQUEST_CONTENTGROUP,
            payload: response
          })

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          //dispatch(commentActions.createComment(formData, workspace_id, content_group_id, activeTab));
          dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, objValues.parent_id == null, true));
          dispatch(alertActions.success(userConstants.ACCEPT_REQUEST_CONTENTGROUP));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }

    setIsDisabled(false);
  }
}

export function rejectRequestContentGroup(content_group_id, workspace_id, approved_content_group_stage_id, activeTab, objValues, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  let formData = new FormData();

  buildFormDataFromObject(formData, objValues, "comment");

  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/approved_content_group_stages/${approved_content_group_stage_id}/reject_revision`, formData)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.REJECT_REQUEST_CONTENTGROUP,
            payload: response
          })

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          //dispatch(commentActions.createComment(formData, workspace_id, content_group_id, activeTab));
          dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, objValues.parent_id == null, true));
          dispatch(alertActions.success(userConstants.REJECT_REQUEST_CONTENTGROUP));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
    setIsDisabled(false);
  }
}

export function approveContentGroup(content_group_id, workspace_id, approved_content_group_stage_id, activeTab, objValues, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  let formData = new FormData();
  buildFormDataFromObject(formData, objValues, "comment");

  return function (dispatch) {
    try{
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/approved_content_group_stages/${approved_content_group_stage_id}`, formData)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.APPROVE_CONTENTGROUP,
            payload: response
          })

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          //dispatch(commentActions.createComment(formData, workspace_id, content_group_id, activeTab));
          dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, objValues.parent_id == null, true));
          dispatch(alertActions.success(userConstants.APPROVE_CONTENTGROUP));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })

    }
    setIsDisabled(false);
  }
}

export function rejectContentGroup(content_group_id, workspace_id,approved_content_group_stage_id) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  return function (dispatch) {
    try{
      makePOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/approved_content_group_stages/${approved_content_group_stage_id}/request_revision`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.REJECT_CONTENTGROUP,
            payload: response
          })
          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })
          dispatch(alertActions.success(userConstants.REJECT_CONTENTGROUP));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function requestRevisionContentGroup(content_group_id, workspace_id, approved_content_group_stage_id, activeTab, objValues, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  let formData = new FormData();

  buildFormDataFromObject(formData, objValues, "comment");
  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/approved_content_group_stages/${approved_content_group_stage_id}/request_revision`, formData)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.REJECT_CONTENTGROUP,
            payload: response
          })

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, formData.parent_id == null, true));
          dispatch(alertActions.success(userConstants.REJECT_CONTENTGROUP));
          //dispatch(commentActions.createComment(formData, workspace_id, content_group_id, activeTab));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }

    setIsDisabled(false);
  }
}

export function assignContentLabelToContentGroup(workspaceId, contentGroupId, requestParams, content_popup_notification, callback) {
  return function(dispatch) {
    try {
      let apiUrl = `/api/v1/workspaces/${workspaceId}/content_groups/${contentGroupId}/content_labels`
      makePOSTRequest(apiUrl, requestParams)
      .then(response => {
        if (response.data.status === 'ok') {
          if (content_popup_notification === undefined) {
            dispatch({
              type: userConstants.ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP,
              payload: response
            })
          }

          dispatch({
            type: userConstants.ASSIGN_LABEL_IN_CONTENT,
            payload: response
          })

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          dispatch(alertActions.success(userConstants.ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP))
        } else {
          dispatch({
            type: userConstants.ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP_FAILURE,
            payload: response.data.error
          })

          dispatch(alertActions.error(response.data.error))
        }
      })
    } catch(e) {
      dispatch({
        type: userConstants.ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP_FAILURE,
        payload: console.log(e)
      })
    }

    callback()
  }
}

export function getContentGroup(workspace_id, content_popup_notification) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          if(content_popup_notification == undefined){
            dispatch({
              type: userConstants.WORKSPACE_SHOW,
              payload: response
            })
          }

          dispatch({
            type: userConstants.GET_CONTENTGROUP,
            payload: response
          })

        }else {
          history.push('/');
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editContentGroup(workspace_id, content_group_id, openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_CONTENTGROUP,
            payload: response
          })

          openModal(true);
        }else {
          history.push('/');
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function archiveContentGroup(workspace_id, content_group_id, archived, closeModal) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/archive`)
      .then(response => {
        if(response.data.status === "ok"){
          const getContentParams = {
            workspace_id,
            content_group_id
          };

          dispatch(contentActions.getContent(getContentParams));

          if (archived)
          {
            dispatch(alertActions.success(userConstants.UNARCHIVE_CONTENTGROUP));
          }
          else{
            dispatch(alertActions.success(userConstants.ARCHIVE_CONTENTGROUP));
          }

          closeModal();
        }else {
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyContentGroup(workspace_id, content_group_id, closeModal) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          const dont_load_workspace = true;
          dispatch(work_spaceActions.showWorkSpace(workspace_id, dont_load_workspace));
          closeModal();
          dispatch({
            type: userConstants.DESTROYED_CONTENTGROUP,
            payload: response
          })
          dispatch(alertActions.success(userConstants.DELETE_CONTENTGROUP));
        }else {
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function inlineEditContentGroup(handleOnBlur, reqparams, content_group_id, workspace_id, content_popup_notification, setIsDisabled) {
  return function (dispatch) {
    try{
      if(setIsDisabled){
        setIsDisabled(true)
      }
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP_WORKSPACE,
            payload: response.data
          })

          dispatch({
            type: userConstants.CONTENT_INLINE_EDIT,
            payload: response.data
          })
          
          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })

          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, [content_group_id]));

          if(response.data.update_status === "stage_update") {
            dispatch(content_groupActions.getContentGroup(workspace_id, content_popup_notification));
          }

          dispatch(alertActions.success(userConstants.UPDATE_CONTENTGROUP));
  
          if(handleOnBlur) handleOnBlur();

          if(setIsDisabled){
            setIsDisabled(false)
          }
        }else{
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error});
          if(handleOnBlur) handleOnBlur();

          if(setIsDisabled){
            setIsDisabled(false)
          }
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })

      if(setIsDisabled){
        setIsDisabled(false)
      }
    }
  }
}

export function inlineNewContentGroup(workspace_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/new`)
      .then(response => {
        if(response.data.error){
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch({ type: userConstants.NEW_CONTENTGROUP, payload: response});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function replaceContentImageLazeLoading(workspaceId, requestParams) {
  return function(dispatch) {
    try {
      let apiUrl = `/api/v1/workspaces/${workspaceId}/content_groups/replace_image`
      makePOSTRequest(apiUrl, {content_groups: requestParams})
      .then(response => {
        dispatch({
          type: userConstants.REPLACE_CONTENT_IMAGE,
          payload: response
        })
      })
    } catch(e) {
      dispatch({
        type: userConstants.ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP_FAILURE,
        payload: console.log(e)
      })
    }
  }
}

export function sortContentAttachment(reqparams, workspace_id, content_group_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/sort_attachments`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          });

          const load = true;
          const getContentParams = {
            workspace_id,
            content_group_id,
            load
          };

          dispatch(contentActions.getContent(getContentParams));
        }else{
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
