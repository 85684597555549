import { userConstants, notificationConstants } from '../constants';

const initialState = { loading: false, error: '', notifications: [] };

export function notification(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case notificationConstants.GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        notifications_loading: false,
        notifications: action.payload.data.data,
        can_access_content: action.payload.data.can_access_content,
        can_access_edit_title: action.payload.data.can_access_edit_title
      };
    }

    case notificationConstants.UNREAD_NOTIFICAITON_COUNT: {
      return {
        ...state,
        unread_notificationCount: action.payload,
        loading: false,
      }
    }

    case notificationConstants.GET_ACTION_CABLE_NOTIFICAIONS: {
      const state_notification_value = state.notifications.length > 0 ? state.notifications : []
      let response_notifications = [action.payload, ...state_notification_value]
      const un_read_notifications = response_notifications && response_notifications.filter(notification => {
        return notification.is_read === false && notification.summary !== "-" && notification.viewable;
      })

      return {
        ...state,
        loading: false,
        notifications: response_notifications,
        unread_notificationCount: un_read_notifications && un_read_notifications.length
      };
    }

    case notificationConstants.DELETE_ACTION_CABLE_NOTIFICATIONS: {
      const state_notification_value = state.notifications.length > 0 ? state.notifications : []
      let response_notifications = [...state_notification_value].filter(notif => {
        return notif.id !== action.payload.id
      })

      const un_read_notifications = response_notifications && response_notifications.filter(notification => {
        return notification.is_read === false && notification.summary !== "-" && notification.viewable;
      })

      return {
        ...state,
        loading: false,
        notifications: response_notifications,
        deleted_notification: action.payload,
        unread_notificationCount: un_read_notifications && un_read_notifications.length
      };
    }


    case notificationConstants.GET_ALL_FAILURE: {
      return {
        ...state,
        loading: false,
        notifications_loading: false,
        error: action.payload,
      };
    }

    case notificationConstants.READ_ALL_SUCCESS: {
      state.notifications.map(item => item.is_read = true)

      return {
        ...state,
        notifications: state.notifications.map((item) => item),
        loading: false,
        read_all_notifications_loading: false,
      };
    }

    case notificationConstants.READ_ALL_FAILURE: {
      return {
        ...state,
        loading: false,
        read_all_notifications_loading: false,
        error: action.payload,
      };
    }

    case notificationConstants.READ_SUCCESS: {
      let notification = state.notifications.filter(item => item.id == action.payload.data.data.id)[0]

      if (!!notification) {
        notification.is_read = true
      }

      return {
        ...state,
        notifications: state.notifications.map((item) => item),
        notification_load: action.payload.data.data,
        loading: false,
        read_notifications_loading: false,
      };
    }

    case notificationConstants.UNREAD_SUCCESS: {
      let notification = state.notifications.filter(item => item.id == action.payload.data.data.id)[0]

      if (!!notification) {
        notification.is_read = false
      }

      return {
        ...state,
        notifications: state.notifications.map((item) => item),
        notification_load: action.payload.data.data,
        loading: false,
        read_notifications_loading: false,
      };
    }

    case notificationConstants.READ_FAILURE: {
      return {
        ...state,
        loading: false,
        read_notifications_loading: false,
        error: action.payload,
      };
    }

    case notificationConstants.UPDATE_NOTIFICATION_RING: {
      return {
        ...state,
        enable_notification_ring: action.payload,
      }
    }

    default:
      return state
  }
}
